import { createTheme } from '@mui/material/styles';
import localFont from 'next/font/local';
export const fontRoboto = localFont({
  src: [
    {
      path: '../public/fonts/roboto/Roboto-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../public/fonts/roboto/Roboto-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../public/fonts/roboto/Roboto-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  fallback: ['Arial', 'sans-serif'], // Fuente de respaldo en caso de que Roboto no cargue
});

export const fontEncodeSans = localFont({
  src: '../public/fonts/encode-sans/EncodeSans-VariableFont_wdth,wght.ttf',
  style: 'normal',
  fallback: ['Arial', 'sans-serif'], // Fuente de respaldo en caso de que Roboto no cargue
});

const theme = createTheme({
  breakpoints: {
    values: {
      sliderHome: 1265,
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1550,
      schedulerMobile: 750,
    },
  },
  typography: {
    fontFamily: `${fontRoboto.style.fontFamily}`,
    h2: {
      fontSize: '30px',
      fontWeight: '700',
      color: '#1976D2',
      fontFamily: `${fontEncodeSans.style.fontFamily}`,
    },
    h3: {
      fontSize: '22px',
      fontWeight: '700',
      color: '#000000',
      fontFamily: `${fontEncodeSans.style.fontFamily}`,
    },
    h4: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#000000',
      fontFamily: `${fontRoboto.style.fontFamily}`,
    },
    h5: {
      fontSize: '20px',
      fontWeight: '500',
      color: '#000000',
      fontFamily: `${fontRoboto.style.fontFamily}`,
    },
    h6: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000',
      fontFamily: `${fontRoboto.style.fontFamily}`,
    },
    body1: {
      fontFamily: `${fontRoboto.style.fontFamily}`,
    },
    body2: {
      fontFamily: `${fontEncodeSans.style.fontFamily}`,
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
      blue: '#1976D2',
      boBlue: '#ECF2F9',
      grey: '#F2F2F2',
      green: '#2E7D32',
      red: '#D32F2F',
      greyHeaderTable: '#535554',
      greyBorderCard: '#ABACAC',
      greyBackground: '#F5F5F5',
      lightBlue: '#e1eff5',
      darkBlue: '#013654',
      greyViewType: '#969898',
      greyHeaderTableGanttLicenses: '#ececec',
      yellowSearch: '#ECD59980',
      lightBlueBackgroundShare: '#E3F2FDB2',
      borderColorGrey: '#D4D5D5',
    },
    primary: {
      main: '#1976D2',
      light: '#034c8c',
      dark: '#023059',
      contrastText: '#fff',
    },
    secondary: {
      main: '#262626',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#ED6C02',
      light: '#ED6C02',
      dark: '#5F2B01',
    },
    success: {
      main: '#2E7D32',
    },
    disabled: {
      main: '#6F7271',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      disabled: '#8d8d8d',
    },
    scheduler: { myRow: 'rgba(233, 234, 234, 0.5)' },
    license_status: {
      approved: '#2E7D32',
      in_process: '#50B8B1',
      hr_pending: '#50B8B1',
      rejected: '#D32F2F',
      interrupted: '#535554',
      cancelled: '#535554',
      document_pending: '#FFEEE1',
      document_loaded: '#FFEEE1',
      expired: '#535554',
      to_be_discounted: '#D32F2F',
      not_justified: '#D32F2F',
      rrhh_approved: '#2E7D32',
      justified: '#2E7D32',
    },
    directory: { fontLetter: '#6F7271' },
    grey: {
      1: '#f1f1f1',
      3: '#f2f6f8',
      4: '#f7f7f7',
      6: '#E9EAEA',
      5: '#557488',
      7: '#3c5662',
      8: '#535554', // gris 8
      9: '#303638',
      10: '#1C1B1A', // gris 10
    },
  },
  shape: {
    classic: {
      borderRadius: '5px',
      border: '1px solid #EBEBEB',
    },
  },
  shadows: Array(25).fill('none'),
  // shadows: {
  //   0: 'none',
  //   1: '0px 3px 14px rgba(0,0,0,0.12)',
  //   2: '4px 0px 5px rgba(0,0,0,0.14), 1px 0px 10px rgba(0,0,0,0.12), 2px 0px 4px rgba(0,0,0,0.2)',
  //   3: '0px 4px 5px rgba(0,0,0,0.14), 0px 1px 10px rgba(0,0,0,0.12), 0px 2px 4px rgba(0,0,0,0.2)',
  //   6: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rbga(0,0,0,0.12)',
  //   8: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
  //   24: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
  // },
  transitions: {
    easing: {
      ease: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      short: 200,
      standard: 400,
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'primary',
        variant: 'contained',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 50,
          fontFamily: `${fontRoboto.style.fontFamily}`,
          fontWeight: 500,
          height: 36,
          textTransform: 'none',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        autoComplete: 'off',
      },
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '3.5px',
          paddingLeft: '16px',
          paddingRight: '16px',
          textDecoration: 'none',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#535554',
          padding: '8px 25px',
          borderRadius: '6px',
          fontSize: '13px',
        },
        arrow: {
          color: '#535554',
        },
      },
    },
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
        },
      },
    },
  },
});

export default theme;
